import { useState } from "react";
import ButtonSelectTerm from "./ButtonSelectTerm";
import FullTimeInsight from "./FullTimeInsight";

interface ChoseTermProps {
  claseA: number;
  claseB: number;
  individualA: number;
  individualB: number;
  cargaSemanaA:number;
  cargaSemanaB: number;

}

const ChoseTerm = ({claseA, claseB,individualA,individualB, cargaSemanaA, cargaSemanaB}: ChoseTermProps) => {
  const [termOne, setTermOne] = useState(true);
  const [termTwo, setTermTwo] = useState(false);

  const handleClick = (term: 1 | 2) => {
    if (term === 1) {
      setTermOne(true);
      setTermTwo(false);
    } else {
      setTermOne(false);
      setTermTwo(true);
    }
  };
  return (
    <>
      <div className="container mx-auto flex flex-row gap-4 mt-7 mb-4">
        <ButtonSelectTerm
          isSelected={termOne}
          term={1}
          handleClick={handleClick}
        />
        <ButtonSelectTerm
          isSelected={termTwo}
          term={2}
          handleClick={handleClick}
        />
      </div>
      {termOne ? (
        <FullTimeInsight
          horasClaseDia={claseA}
          horasEstudioIndividualDIA={individualA}
          totalHorasSemana={cargaSemanaA}
        />
      ) : (
        <FullTimeInsight
          horasClaseDia={claseB}
          horasEstudioIndividualDIA={individualB}
          totalHorasSemana={cargaSemanaB}
        />
      )}
    </>
  );
};

export default ChoseTerm;
