import { useState, useEffect } from "react";
const poker = require("../assets/img/poker.png");
const happy = require("../assets/img/happy.png");
const sad = require("../assets/img/sad.png");

interface IBalanceSemestreProps {
  creditos: number;
}

const BalanceSemestre = ({ creditos }: IBalanceSemestreProps) => {
  let [rutaImagen, setImagen] = useState();
  let [bgColor, setBgColor] = useState<string>();
  let [textSiNo, setTextoSiNo] = useState<string>();
  let [textoAyuda, setTexto] = useState<string>();

  const colores = {
    si: "#05E8B6",
    maso: "#FFD85A",
    no: "#FF7961",
  };

  const selectIcon = () => {
    if (creditos <= 20) {
      setImagen(happy);
      setTextoSiNo("Sí");
      setBgColor(colores.si);
      setTexto(
        "Tienes un horario bonito y bien balanceadito, ánimo con las clases y ojo con el sueño y la comida :-)"
      );
    } else if (creditos > 20 && creditos <= 22) {
      setImagen(poker);
      setBgColor(colores.maso);
      setTextoSiNo("Maso");
      setTexto(
        "Upa, este horario como que tiene demasiadas horas, pero sigue siendo manejable. Mejor corta algunas clases, reconsidera si necesitas meter tantas materias o elige algunas que tomen menos tiempo."
      );
    } else if (creditos > 22) {
      setImagen(sad);
      setBgColor(colores.no);
      setTextoSiNo("No");
      setTexto(
        "Compa no te hagas eso, con este horario te espera mucho trasnocho y pocas horas tranquilas. En serio no te recomendamos que te sobrecargues, mejor haz remix a ese horario."
      );
    }
  };

  useEffect(() => {
    selectIcon();
  });

  return (
    <div className="container mx-auto">
      <div className="flex flex-col gap-3">
        <p className="text-2xl font-semibold px-6">
          ¿Tengo un semestre balanceado?
        </p>
        <div className="flex flex-col p-4 gap-4 md:flex-row">
          <div className="flex flex-row p-2 basis-1/4 items-center justify-center gap-10">
            <div
              className="rounded-full py-2"
              style={{ backgroundColor: bgColor }}
            >
              <img
                className="object-contain max-h-full"
                src={rutaImagen}
                alt="Imagen de una carita"
                width={110}
              />
            </div>
            <div className="text-6xl"> {textSiNo} </div>
          </div>

          <div className="basis-2/4 flex justify-center items-center ">
            <p className="text-xl"> {textoAyuda} </p>
          </div>

          <div className="basis-1/4 flex justify-center items-center ">
            <h2 className="text-3xl font-bold ">{creditos} créditos </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BalanceSemestre;
