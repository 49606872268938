import React from "react";
import { BsInstagram } from "react-icons/bs";

import { AiOutlineMail } from "react-icons/ai";

const LogoDeca = require("../assets/img/decalogo.png");

const Footer = () => {
  return (
    <footer className="bg-black text-white flex flex-row p-5 py-3 gap-2 items-center justify-between ">
      <div className="flex flex-col items-start gap-1 justify-center p-3">
        <div className="flex flex-row">
          <button
            onClick={() =>
              window.open(
                "https://www.instagram.com/planny.academico/",
                "_blank"
              )
            }
          >
            <BsInstagram size={25} />
          </button>
          <p>@planny.academico</p>
        </div>

        <div className="flex flex-row">
          <AiOutlineMail size={25} />
          <p>Contáctanos: planny.academico@gmail.com</p>
        </div>
      </div>

      <a
        href="https://www.eluniversal.com.co/binrepository/707x700/0c0/0d0/none/13704/QQTR/diomedez-diaz_4631544_20210525143409.jpg"
        target="_blank"
      >
        {" "}
        <p className="text-black text-xl"> F </p>
      </a>

      <div className="flex flex-col items-start justify-center">
        <p>Creado por y para estudiantes. :)</p>
        <p>Pablo Arango, Juanpablo Barriga, Sofía Castañeda, Geisson Ponce</p>
      </div>
    </footer>
  );
};

export default Footer;
