import { Link } from "react-router-dom";

interface SwitchOptionProps {
  isActive: boolean;
  toPath: string;
  text: string;
}
const SwitchOption = ({
  toPath,
  text,
  isActive = false,
}: SwitchOptionProps) => {
  const clase = isActive ? "bg-pdark" : "bg-[#d6e0fc]";
  return (
    <div>
      <Link
        to={toPath}
        className={"px-4 py-2 text-xl rounded-xl text-white " + clase}
      >
        {text}
      </Link>
    </div>
  );
};

export default SwitchOption;
