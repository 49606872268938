interface HeadTextProps {
  totalCredits: number;
}

const HeadText = ({ totalCredits }: HeadTextProps) => {
  return (
    <div className="container mx-auto">
      <p className="text-xl pt-4 px-3 md:px-0">
        Si inscribo <strong>{totalCredits}</strong> créditos este semestre, mi
        carga semanal aproximada será...
      </p>
    </div>
  );
};

export default HeadText;
