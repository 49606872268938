import SwitchOption from "./SwitchOption";

interface SwitchProps {
  resultsActive: boolean;
  termsActive: boolean;
}
const Switch = ({ resultsActive, termsActive }: SwitchProps) => {
  return (
    <div className="my-4 flex flex-row gap-2 container mx-auto pt-5">
      <SwitchOption
        toPath="/results"
        text="16 semanas"
        isActive={resultsActive}
      />
      <SwitchOption
        toPath="/results/terms"
        text="Por ciclos"
        isActive={termsActive}
      />
    </div>
  );
};

export default Switch;
