import { Dispatch, SetStateAction, useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface ICoursesButtonProps {
  setCursosData: Dispatch<SetStateAction<never[]>>;
  data: any[];
}

const CoursesButton = (props: ICoursesButtonProps) => {
  const navigate = useNavigate();

  //Traer datos del local storage (cursos)
  //const realCourses = JSON.parse(localStorage.getItem("cursos") || "[]");

  //console.log(realCourses);

  const handleViewChange = () => {
    navigate("/results/courses");
    //props.setCursosData(realCourses as never[]);
  };

  return (
    <div className="flex flex-row items-center justify-center gap-3">
      <button
        className="bg-[#0037CA] h-fit	 hover:bg-blue-700 text-white font-bold py-3 px-10 m-2 rounded-xl"
        onClick={() => handleViewChange()}
      >
        Haz click aquí para ver el tiempo que requiere cada clase
        <span className="inline-flex items-center justify-center w-4 h-4 ml-2 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full">
          <p>?</p>
        </span>
      </button>

      <div className="basis-1/3 py-10 px-8 bg-[#D6E0FC] text-center	 m-2 rounded-xl space-y-2 sm:py-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6">
        Aquí podrás ver las horas de clase y las horas de trabajo individual que
        requiere cada una de tus materias.
      </div>
    </div>
  );
};

export default CoursesButton;
