interface HarderTermProps {
  harder: any;
}
const HarderTerm = ({ harder }: HarderTermProps) => {
  return (
    <div className="container mx-auto">
      <p className="text-xl pt-4 px-3 md:px-0">
        Ciclo con mayor intesidad de horas: <strong>Ciclo {harder}</strong>
      </p>
    </div>
  );
};

export default HarderTerm;
