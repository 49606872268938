import { useEffect, useState } from "react";

interface IViewHorasProps {
  horasDisponibles: number;
}

const ViewHoras = ({ horasDisponibles }: IViewHorasProps) => {
  const [hoursSleep, setHoursSleep] = useState(0);
  const [hoursTransport, setHoursTransport] = useState(0);
  const [hoursEntertiment, setHoursEntertiment] = useState(0);
  const [hoursEat, setHoursEat] = useState(0);


  const [alcanza, setAlcanza] = useState(horasDisponibles > 0);
  const [disponibles, setDisponibles] = useState(horasDisponibles);

  const handleChange = (pName: string, pHours: number) => {
    switch (pName) {
      case "sleep":
        setHoursSleep(pHours * 7);
        break;
      case "eat":
        setHoursEat(pHours * 7);
        break;
      case "transport":
        setHoursTransport(pHours * 5);
        break;
      case "entertiment":
        setHoursEntertiment(pHours * 7);
        break;

      default:
        break;
    }
  };

  const newTab = (href: string) => {
    window.open(href, "_blank");
  };

  useEffect(() => {
    const totalHoras = hoursSleep + hoursEntertiment + hoursTransport + hoursEat;
    setDisponibles(horasDisponibles - totalHoras);
    setAlcanza(horasDisponibles - totalHoras >= 0);
  }, [hoursSleep, hoursEntertiment, hoursTransport, hoursEat, horasDisponibles]);

  return (
    <div className="container flex flex-col items-start pl-10">
      <div className="flex h-full">
        <div className="h-full flex flex-col justify-between w-2/12 gap-8 pl-0 pr-7 py-6">
          <input
            type="number"
            className="h-1/3 font-bold placeholder-black placeholder-opacity-50 bg-slightw rounded-lg border-2 border-slightw"
            placeholder="0"
            name="sleep"
            min={0}
            onChange={(event) =>
              handleChange(event.target.name, Number(event.target.value))
            }
          ></input>
          <input
            type="number"
            className="h-1/3 font-bold placeholder-black placeholder-opacity-50 bg-slightw rounded-lg border-2 border-slightw"
            placeholder="0"
            name="eat"
            min={0}
            onChange={(event) =>
              handleChange(event.target.name, Number(event.target.value))
            }
          ></input>
          <input
            type="number"
            className="h-1/3 font-bold placeholder-black placeholder-opacity-50 bg-slightw rounded-lg border-2 border-slightw"
            placeholder="0"
            min={0}
            name="transport"
            onChange={(event) =>
              handleChange(event.target.name, Number(event.target.value))
            }
          ></input>
          <input
            type="number"
            name="entertiment"
            className="h-1/3 font-bold placeholder-black placeholder-opacity-50 bg-slightw rounded-lg border-2 border-slightw"
            placeholder="0"
            min={0}
            onChange={(event) =>
              handleChange(event.target.name, Number(event.target.value))
            }
          ></input>
        </div>

        <div className=" flex flex-col justify-between p-4 gap-8 py-6">
          <p className="h-1/3"> horas diarias de <strong>sueño</strong></p>

          <p className="h-1/3"> horas diarias de <strong>alimentación</strong></p>

          <p className="h-1/3">horas diarias en <strong>transporte</strong> (lunes - viernes)</p>

          <p className="h-1/3"> horas diarias como <strong>tiempo libre</strong></p>
        </div>
      </div>
      <p className="self-center text-xl">
        Tengo{" "}
        <strong className="text-2xl"> {Math.floor(disponibles)} horas</strong>{" "}
        disponibles
      </p>
      <p className="self-center text-xl">
        <strong className="text-2xl">{alcanza ? "Sí" : "No"}</strong> me alcanza
        el tiempo
      </p>
      
    </div>
  );
};

export default ViewHoras;
