import React from "react";

interface ICalculate {
  totalCredits: number;
  handleViewChange: () => void;
}

const Calculate = ({ handleViewChange, totalCredits }: ICalculate) => {
  return (
    <div className="flex items-center justify-end pr-12 pb-3 text-2xl">
      <div className="flex flex-col items-center justify-center p-3 m-2">
        <h1 className="font-bold">Créditos totales</h1>
        <h1 className="font-extrabold text-4xl">{totalCredits}</h1>
      </div>
      <button
        onClick={handleViewChange}
        disabled={totalCredits === 0}
        className="bg-green-500 text-white px-7 py-1 rounded-2xl font-bold hover:bg-green-800 h-10 mx-4 my-3 disabled:bg-gray-500 disabled:cursor-not-allowed"
      >
        Calcular
      </button>
    </div>
  );
};

export default Calculate;
