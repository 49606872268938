import React from "react";
import * as V from "victory";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

interface IResultsCoursesProps {
  cursos: any[];
}

const ResultsCourses = ({ cursos }: IResultsCoursesProps) => {
  const navigate = useNavigate();

  let listaDuracionesClase = [{}];
  let listaDuracionesIndividual = [{}];

  let listaColors = [
    "#3D5AFE",
    "#1DE9B6",
    "#A7FFEB",
    "#8187FF",
    "#0031CA",
    "#6EFFE8",
    "#DBFFFF",
    "#0031CA",
    "#00B686",
    "#75CCB9",
  ];

  const handleVolver = () => {
    navigate("/results");
  };

  const dataGraficas = () => {
    for (let curso of cursos) {
      let infoCursoClase = { x: 0, y: 0, label: "" };
      let infoCursoIndividual = { x: 0, y: 0, label: "" };
      infoCursoClase.x = curso.codigo;
      infoCursoClase.y = curso.horasClaseMinutos;
      infoCursoClase.label = curso.codigo;
      infoCursoIndividual.x = curso.codigo;
      infoCursoIndividual.y = curso.horasEstudioMinutos;
      infoCursoIndividual.label = curso.codigo;

      listaDuracionesClase.push(infoCursoClase);
      listaDuracionesIndividual.push(infoCursoIndividual);
    }

    listaDuracionesClase.shift();
    listaDuracionesIndividual.shift();
  };

  dataGraficas();

  return (
    <div className="container mx-auto px-6 py-3 flex flex-col">
      <p className="text-xl p-4">
        Aquí puedes ver el tiempo que ocupa cada clase en tu semana
      </p>
      <div className="py-3 px-8 w-full h-full mx-auto bg-[#EDF0FF] m-2 rounded-xl justify-center items-center  shadow-lg space-y-2 sm:py-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6">
        <div className="flex flex-col justify-center items-center">
          <p className="text-lg font-bold">
            {" "}
            Distribución de Carga Semanal por Materia{" "}
          </p>
          <div className="flex flex-col md:flex-row justify-center items-center">
            <div className="py-1 m-2 space-y-1 sm:py-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6">
              <div className="flex flex-col justify-center items-center">
                <p className="font-semibold"> Horas de Clase</p>
                <V.VictoryPie
                  width={500}
                  style={{
                    labels: {
                      fontSize: 15,
                      padding: 10,
                    },
                  }}
                  colorScale={listaColors}
                  data={listaDuracionesClase}
                />
              </div>
            </div>
            <div className="py-1 m-2 space-y-1 sm:py-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6">
              <div className="flex flex-col justify-center items-center">
                <p className="font-semibold">Horas de Estudio Individual</p>
                <V.VictoryPie
                  width={500}
                  style={{
                    labels: {
                      fontSize: 15,
                      padding: 10,
                    },
                  }}
                  colorScale={listaColors}
                  data={listaDuracionesIndividual}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <p className="text-xl p-4">
        Semanalmente, el tiempo que requieren las clases de tu plan de horario
        es el siguente:
      </p>
      <div className="flex flex-col">
        {cursos?.map((item, index) => (
          <div
            key={index}
            className="py-10 px-8 w-full h-full mx-auto bg-[#EDF0FF] m-2 rounded-xl justify-center items-center  shadow-lg space-y-2 sm:py-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6"
          >
            <div className="flex flex-col w-full">
              <div className="flex flex-row w-full items-end">
                <p className="font-bold">{item.nombre} </p>
                <p className="px-5 text-sm"> {item.codigo}</p>
                <p className="text-sm"> NRC: {item.nrc}</p>
              </div>

              <p> Duración: {item.semanas} semanas</p>
              <div className="flex flex-row items-stretch">
                <div className="basis-1/2 py-10 px-8 w-full mx-auto bg-[#88A0FF] m-2 rounded-xl justify-center items-center  shadow-lg space-y-2 sm:py-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6">
                  <div className="flex flex-col w-full h-full justify-center items-center">
                    <p className="text-xl font-semibold">
                      {" "}
                      {Math.floor(item.horasClaseMinutos / 60)} horas y{" "}
                      {Math.floor(((item.horasClaseMinutos / 60) % 1) * 60)}{" "}
                      minutos{" "}
                    </p>
                    <p> de clases presenciales</p>
                  </div>
                </div>
                <div className="basis-1/2 py-10 px-8 w-full mx-auto bg-[#B4E3FF] m-2 rounded-xl justify-center items-center  shadow-lg space-y-2 sm:py-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6">
                  <div className="flex flex-col w-full h-full justify-center items-center">
                    <p className="text-xl font-semibold">
                      {Math.floor(item.horasEstudioMinutos / 60)} horas y{" "}
                      {Math.floor(((item.horasEstudioMinutos / 60) % 1) * 60)}{" "}
                      minutos
                    </p>
                    <p> a actividades de clase</p>
                    <p> (ej: tareas, talleres y preparar examenes/entregas)</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <button
        onClick={handleVolver}
        className="font-bold text-xl py-2 px-4 m-3 md:mx-20 md:py-3 bg-hardblue text-white rounded-2xl flex gap-3 items-center justify-center hover:bg-hardbluedark self-end"
      >
        Volver
        <FaArrowLeft />
      </button>
    </div>
  );
};

export default ResultsCourses;
