import { Dispatch, SetStateAction, useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import CourseDetail from "./CourseDetail";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";

interface IProps {
  courseInfo: any;
  setAddedCourses: Dispatch<SetStateAction<any[]>>;
  addedCourses: any[];
}

interface IInstructor {
  name: string;
  ind: string | null;
}
const AddedCourse = ({ courseInfo, addedCourses, setAddedCourses }: IProps) => {
  const [isSelected, setIsSelected] = useState(false);

  const handleDelete = () => {
    setAddedCourses((prev) =>
      prev.filter((course) => course["llave"] !== courseInfo["llave"])
    );
  };

  const principalInstructor = courseInfo.instructors.find(
    (instructor: IInstructor) => instructor.ind !== null
  ) ?? { name: "DESCONOCIDO" };

  return (
    <>
      <div className="flex flex-row bg-white p-4 rounded-2xl hover:bg-gray-100 items-center">
        <div
          className="flex flex-col w-10/12 hover:cursor-pointer"
          onClick={() => setIsSelected((prev) => !prev)}
        >
          <p className="font-semibold">{courseInfo["title"]}</p>
          <p className="text-gray-700">{principalInstructor.name}</p>
          <div className="flex text-gray-600 py-1 text-sm items-center">
            <p>MÁS INFO</p>
            {isSelected ? <AiFillCaretUp /> : <AiFillCaretDown />}
          </div>
        </div>
        <button
          onClick={handleDelete}
          className="bg-red-500 text-white rounded-full hover:bg-red-700 flex items-center justify-center h-12 w-12"
        >
          <RiDeleteBin6Line size={25} />
        </button>
      </div>
      {isSelected && <CourseDetail allCourseInfo={courseInfo} />}
    </>
  );
};

export default AddedCourse;
