import ViewHoras from "../../views/ViewHoras";

interface IFullTimeInsightProps {
  horasClaseDia: number;
  horasEstudioIndividualDIA: number;
  totalHorasSemana: number;
}

const FullTimeInsight = ({
  horasClaseDia,
  horasEstudioIndividualDIA,
  totalHorasSemana,
}: IFullTimeInsightProps) => {
  return (
    <div className="container mx-auto m-5">
      <div className="flex flex-col">
        <p className="text-xl p-4">
          En un <strong>día promedio</strong>, para cumplir con la carga
          académica, debo dedicar:
        </p>
        <div className="flex flex-col p-3 md:flex-row">
          <div className="container mx-auto bg-white rounded-xl shadow-md">
            <div className="flex flex-col md:flex-row h-full">
              <div className="flex bg-[#7DB2FF] rounded-xl  items-center justify-center p-4">
                <div className="flex flex-col justify-center items-center">
                  <p className="text-xl"> De las</p>
                  <p className="text-xl font-semibold">24 horas</p>
                  <p className="text-xl"> del día</p>
                </div>
              </div>
              <div className="basis-3/4 flex flex-col items-stretch m-2">
                <div className="py-10 px-8 w-full h-full mx-auto bg-[#88A0FF] m-2 rounded-xl justify-center items-center space-y-2 sm:py-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6">
                  <div className="flex flex-row space-x-10">
                    <p className="text-lg">
                      {" "}
                      <strong>{Math.floor(horasClaseDia)}</strong> horas y{" "}
                      <strong>{Math.floor((horasClaseDia % 1) * 60)}</strong>{" "}
                      minutos
                    </p>
                    <p> a clases presenciales</p>
                  </div>
                </div>
                <div className="py-10 px-8  w-full h-full mx-auto bg-[#B4E3FF] m-2 rounded-xl justify-center items-center space-y-2 sm:py-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6">
                  <div className="flex flex-row space-x-10">
                    <p className="text-lg">
                      <strong>{Math.floor(horasEstudioIndividualDIA)} </strong>
                      horas y{" "}
                      <strong>
                        {Math.floor((horasEstudioIndividualDIA % 1) * 60)}
                      </strong>{" "}
                      minutos{" "}
                    </p>
                    <p> a actividades de clase</p>
                  </div>
                </div>
                <div className="py-10 px-8 w-full h-full mx-auto bg-[#A5FFEB] m-2 rounded-xl justify-center items-center space-y-2 sm:py-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6">
                  <div className="flex flex-col items-center justify-center ">
                    <p className="text-lg"> Me quedan disponibles </p>
                    <p className="text-lg">
                      <strong>
                        {Math.floor(
                          24 - horasClaseDia - horasEstudioIndividualDIA
                        )}
                      </strong>{" "}
                      horas y{" "}
                      <strong>
                        {Math.floor(
                          ((24 - horasClaseDia - horasEstudioIndividualDIA) %
                            1) *
                            60
                        )}{" "}
                      </strong>
                      minutos entre semana
                    </p>
                    <p className="text-lg">
                      <strong>
                        {Math.floor(24 - horasEstudioIndividualDIA)}
                      </strong>{" "}
                      horas y{" "}
                      <strong>
                        {Math.floor(
                          ((24 - horasEstudioIndividualDIA) % 1) * 60
                        )}{" "}
                      </strong>
                      minutos los días de fin de semana
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container bg-white rounded-xl mx-1 shadow-md">
            <ViewHoras horasDisponibles={168 - totalHorasSemana}></ViewHoras>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullTimeInsight;
