import React, { Dispatch, SetStateAction } from "react";
import Loading from "../utils/Loading";
import Course from "./Course";

interface IProps {
  courses: any[];
  loading: boolean;
  setAddedCourses: Dispatch<SetStateAction<any[]>>;
  addedCourses: any[];
}

const SearchedCourses = ({
  courses = [],
  loading = false,
  setAddedCourses,
  addedCourses,
}: IProps) => {
  return (
    <div className="flex flex-col gap-2">
      <h4 className="font-extrabold p-4 text-xl">Cursos encontrados</h4>
      <div className="flex flex-col border rounded-xl p-4 gap-2 bg-gray-200 h-80 overflow-y-auto shadow-md">
        {!loading ? (
          courses.length > 0 ? (
            courses.map((course, index) => {
              if (addedCourses.find(({ llave }) => llave === course.llave)) {
                return null;
              }
              return (
                <Course
                  key={index}
                  courseInfo={course}
                  addedCourses={addedCourses}
                  setAddedCourses={setAddedCourses}
                />
              );
            })
          ) : (
            <h1 className="m-auto text-xl">No se ha encontrado ningún curso</h1>
          )
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default SearchedCourses;
