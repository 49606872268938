import React from "react";

interface IProps {
  allCourseInfo: any;
}

const CourseDetail = ({ allCourseInfo }: IProps) => {
  return (
    <div className="bg-gray-50 p-5 rounded-2xl mb-2 ml-10 mr-3">
      <p>
        <strong>Créditos:</strong> {allCourseInfo["credits"]}
      </p>
      <p>
        <strong>Sección:</strong> {allCourseInfo["section"]}
      </p>
      <p>
        <strong>NRC:</strong> {allCourseInfo["nrc"]}
      </p>
      <p>
        <strong>Campus:</strong> {allCourseInfo["campus"]}
      </p>
    </div>
  );
};

export default CourseDetail;
