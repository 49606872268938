import { useState } from "react";
import BottonButtoms from "../components/ResultsTerm/BottonButtoms";
import {
  CoursesButton,
  HeadText,
  TermChart,
  HarderTerm,
  ChoseTerm,
} from "../components/ResultsTerm/_index";
import BalanceSemestre from "./BalanceSemestre";

const ResultsTerms = () => {
  const [cursos, setCursosData] = useState([]);
  const [resultsData, setResultsData] = useState([]);

  //Traer datos del local storage (cursos)
  const realCourses = JSON.parse(localStorage.getItem("cursos") || "[]");

  //Variables
  let creditosSemestre = 0; //Creditos del semestre total
  let duracionTotalClasesMIN_A = 0; //Minutos de clase en total ciclo A(Solo clase)
  let duracionTotalClasesMIN_B = 0; //Minutos de clase en total ciclo B(Solo clase)
  let totalHorasSemana_A = 0; //Horas a la semana de carga ciclo A. (Clase + individual) Promedio.
  let totalHorasSemana_B = 0; //Horas a la semana de carga ciclo B (Clase + individual) Promedio.
  let horasClaseSemana_A = 0; //horas de clase en una semana ciclo A. (Solo clase)
  let horasClaseSemana_B = 0; //horas de clase en una semana ciclo B. (Solo clase)
  let estudioIndividualSemanaHoras_A = 0; //horas de estudio individual a la semana ciclo A(Solo individual)
  let estudioIndividualSemanaHoras_B = 0; //horas de estudio individual a la semana ciclo B (Solo individual)
  let horasEstudioIndividualDIA_A = 0; //Horas de estudio individual promedio en un dia ciclo A
  let horasEstudioIndividualDIA_B = 0;
  let horasClaseDia_A = 0; //Horas de clase promedio en un dia ciclo A
  let horasClaseDia_B = 0;

  let creditosSemestre_A = 0;
  let creditosSemestre_B = 0;

  let cicloMasPesado = 1;

  function calcularCarga(): void {
    for (let curso of realCourses) {
      creditosSemestre += curso.creditosCurso;

      if (curso.periodo === "1") {
        //Minutos de clase a la semana
        duracionTotalClasesMIN_A += curso.totalMinutosClase / 2;
        duracionTotalClasesMIN_B += curso.totalMinutosClase / 2;

        //Horas total de carga a la semana
        totalHorasSemana_A += Number(curso.horasTotalSemana);
        totalHorasSemana_B += Number(curso.horasTotalSemana);

        creditosSemestre_A += curso.creditosCurso;
        creditosSemestre_B += curso.creditosCurso;
      } else if (curso.periodo === "8A") {
        duracionTotalClasesMIN_A += curso.totalMinutosClase;
        totalHorasSemana_A += Number(curso.horasTotalSemana);
        creditosSemestre_A += curso.creditosCurso;
      } else if (curso.periodo === "8B") {
        duracionTotalClasesMIN_B += curso.totalMinutosClase;
        totalHorasSemana_B += Number(curso.horasTotalSemana);
        creditosSemestre_B += curso.creditosCurso;
      }
    }

    horasClaseSemana_A = duracionTotalClasesMIN_A / 8 / 60;
    horasClaseSemana_B = duracionTotalClasesMIN_B / 8 / 60;

    estudioIndividualSemanaHoras_A = totalHorasSemana_A - horasClaseSemana_A;
    estudioIndividualSemanaHoras_B = totalHorasSemana_B - horasClaseSemana_B;

    horasEstudioIndividualDIA_A = estudioIndividualSemanaHoras_A / 7; //Horas a Estudiar por dia (Solo individual)
    horasClaseDia_A = horasClaseSemana_A / 5; //Promedio de horas de clase al dia
    horasEstudioIndividualDIA_B = estudioIndividualSemanaHoras_B / 7; //Horas a Estudiar por dia (Solo individual)
    horasClaseDia_B = horasClaseSemana_B / 5; //Promedio de horas de clase al dia

    if (totalHorasSemana_A > totalHorasSemana_B) {
      cicloMasPesado = 1;
    } else {
      cicloMasPesado = 2;
    }
  }

  calcularCarga();
  return (
    <div>
      <HeadText totalCredits={creditosSemestre} />
      <div className="flex flex-col md:flex-row justify-center gap-12 items-center">
        <TermChart
          term={1}
          horasClasesPresenciales={horasClaseSemana_A}
          horasEstudioIndividual={estudioIndividualSemanaHoras_A}
          totalCarga={totalHorasSemana_A}
          creditosCiclo={creditosSemestre_A}
        />
        <TermChart
          term={2}
          horasClasesPresenciales={horasClaseSemana_B}
          horasEstudioIndividual={estudioIndividualSemanaHoras_B}
          totalCarga={totalHorasSemana_B}
          creditosCiclo={creditosSemestre_B}
        />
      </div>
      <HarderTerm harder={cicloMasPesado} />
      <CoursesButton data={resultsData} setCursosData={setCursosData} />
      <div className="container mx-auto">
        <p className="text-xl pt-4 px-3 md:px-0">
          En un día promedio, para cumplir con la carga académica, debo dedicar:
        </p>
      </div>
      <ChoseTerm
        claseA={horasClaseDia_A}
        claseB={horasClaseDia_B}
        individualA={horasEstudioIndividualDIA_A}
        individualB={horasEstudioIndividualDIA_B}
        cargaSemanaA={totalHorasSemana_A}
        cargaSemanaB={totalHorasSemana_B}
      />
      <BalanceSemestre creditos={creditosSemestre} />
      <BottonButtoms />
    </div>
  );
};

export default ResultsTerms;
