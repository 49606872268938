import { useState } from "react";
import ButtonSelectTerm from "../components/ResultsTerm/ButtonSelectTerm";
import ResultsCourses from "./ResultsCourses";

interface ChooseTermGraphProps {
  cursos: any[];
}

const ChooseTermGraphs = ({ cursos }: ChooseTermGraphProps) => {
  const [termOne, setTermOne] = useState(true);
  const [termTwo, setTermTwo] = useState(false);

  let cursosA = [""];
  let cursosB = [""];

  cursos = JSON.parse(localStorage.getItem("cursos") || "[]");

  function calcularCarga(): void {
    cursosA.pop();
    cursosB.pop();
    for (let curso of cursos) {
      if (
        curso.periodo === "1" || //1 = Periodo completo
        curso.periodo === "8A" || //8A = Ciclo A
        curso.periodo === "3" || //3 = Cursos medicina
        curso.periodo ==="2"  ||//2 = Practica
        curso.periodo ==="D" //D = Deporte
      ) {
        cursosA.push(curso);
      }
      if (
        curso.periodo === "1" || 
        curso.periodo === "8B" ||
        curso.periodo === "3" ||
        curso.periodo ==="2" ||
        curso.periodo ==="D" 
      ) {
        cursosB.push(curso);
      }
    }
  }

  calcularCarga();
  const handleClick = (term: 1 | 2) => {
    if (term === 1) {
      setTermOne(true);
      setTermTwo(false);
    } else {
      setTermOne(false);
      setTermTwo(true);
    }
  };
  return (
    <>
      <div className="container mx-auto flex flex-row gap-4 mt-7 mb-4">
        <ButtonSelectTerm
          isSelected={termOne}
          term={1}
          handleClick={handleClick}
        />
        <ButtonSelectTerm
          isSelected={termTwo}
          term={2}
          handleClick={handleClick}
        />
      </div>
      {termOne ? (
        <ResultsCourses cursos={cursosA} />
      ) : (
        <ResultsCourses cursos={cursosB} />
      )}
    </>
  );
};

export default ChooseTermGraphs;
