import React from "react";
import { useNavigate } from "react-router-dom";

const Logo = require("../assets/img/Planny-icono-transparente.png");

const Header = () => {
  const navigate = useNavigate();

  const returnHome = () => {
    navigate("/");
  };

  return (
    <header>
      <nav className="bg-primary">
        <div className="flex p-2">
          <div
            onClick={returnHome}
            className="flex items-center hover:cursor-pointer w-4/12 justify-start pl-7"
          >
            <img
              className="filter invert"
              src={Logo}
              alt="Logo Planny"
              width={95}
            />
            <h2 className=" text-white font-didact text-6xl">Planny</h2>
          </div>
          <h2 className="invisible self-end w-8/12 justify-center pb-5 text-xl text-white md:visible">
            <strong>Calcula tu carga académica</strong> para entender tus créditos y organizar tu tiempo
          </h2>
        </div>
      </nav>
    </header>
  );
};

export default Header;
