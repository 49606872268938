import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Formik } from "formik";
import { BiSearch } from "react-icons/bi";

interface IProps {
  setCourses: Dispatch<SetStateAction<never[]>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

interface ICourse {
  section: string;
}

interface IError {
  section: string;
  code: string;
}

const Search = ({ setCourses, setLoading }: IProps) => {
  const [uInput, setuInput] = useState({ code: "", section: "" });

  useEffect(() => {
    if (uInput.code !== "") {
      setLoading(true);
      fetch(
        `https://ofertadecursos.uniandes.edu.co/api/courses?nameInput=${uInput.code.toUpperCase()}&limit=25`
      )
        .then((res) => res.json())
        .then((data) => {
          if (uInput.section !== "") {
            setCourses(
              data.filter(
                (course: ICourse) => course.section === `${uInput.section}`
              )
            );
          } else {
            setCourses(data);
          }

          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [uInput, setCourses, setLoading]);

  return (
    <Formik
      initialValues={{ code: "", section: "" }}
      validate={(values) => {
        const errors = {} as IError;
        if (!values.code) {
          errors.code = "* Campo requerido";
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        setuInput({ ...values });
      }}
    >
      {({
        values,
        errors,
        handleBlur,
        handleSubmit,
        isSubmitting,
        handleChange,
      }) => (
        <form className="flex flex-col gap-2 p-1" onSubmit={handleSubmit}>
          <h5 className="font-bold text-xl">Busca tus cursos aquí</h5>
          <div className="flex gap-2">
            <input
              className="w-1/2 border border-blue-600 rounded-md p-3"
              id="code"
              type="text"
              name="code"
              placeholder="ADMI1010, Cálculo, 10047"
              value={values.code}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <input
              className="w-1/3 border border-blue-600 rounded-md p-3"
              id="section"
              type="number"
              name="section"
              min="0"
              max="100"
              placeholder="1"
              value={values.section}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <button
              className="rounded-full p-3 bg-blue-600"
              type="submit"
              disabled={isSubmitting}
            >
              <BiSearch size={30} color="white" />
            </button>
          </div>
          <div className="flex gap-5">
            <label className="flex flex-row w-1/2 gap-2" htmlFor="code">
              Código, Nombre o NRC{" "}
              <h1 className="text-red-700">{errors.code}</h1>
            </label>
            <label className="w-1/2" htmlFor="section">
              Sección (opcional)
            </label>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default Search;
