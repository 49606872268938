import { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Footer, Header, Switch } from "./components/_index";
import { Main, Results, ResultsTerms, ChooseTermGraphs } from "./views/_index";

const App = () => {
  const initialStates = {
    resultsData: [] as any[],
    cursos: [] as any[],
  };

  const [resultsData, setResultsData] = useState(initialStates.resultsData);
  const [cursos, setCursosData] = useState(initialStates.cursos);

  return (
    <Router>
      <Header />
      <Routes>
        <Route
          path="/"
          element={
            <Main setResultsData={setResultsData} resultsData={resultsData} />
          }
        />
        <Route
          path="results"
          element={
            <div>
              <Switch resultsActive={true} termsActive={false} />
              <Results data={resultsData} setCursosData={setCursosData} />
            </div>
          }
        />
        <Route
          path="results/terms"
          element={
            <div>
              <Switch resultsActive={false} termsActive={true} />
              <ResultsTerms />
            </div>
          }
        />
        <Route
          path="results/courses"
          element={<ChooseTermGraphs cursos={cursos} />}
        />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
