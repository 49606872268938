import { Dispatch, SetStateAction, useState } from "react";
import { CgAdd } from "react-icons/cg";
import CourseDetail from "./CourseDetail";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";

interface ICourse {
  courseInfo: any;
  addedCourses: any[];
  setAddedCourses: Dispatch<SetStateAction<any[]>>;
}

interface IInstructor {
  name: string;
  ind: string | null;
}

const Course = ({ courseInfo, addedCourses, setAddedCourses }: ICourse) => {
  const [isSelected, setIsSelected] = useState(false);

  const handleAddCourse = () => {
    if (!addedCourses.includes(courseInfo)) {
      if (courseInfo.class === "LENG" && courseInfo.course.startsWith("115")) {
        return setAddedCourses([...addedCourses, { ...courseInfo, credits: 2 }]);
      }
      setAddedCourses([...addedCourses, courseInfo]);
    };
  }

  const principalInstructor = courseInfo.instructors.find(
    (instructor: IInstructor) => instructor.ind !== null
  ) ?? { name: "PROFESOR DESCONOCIDO" };

  return (
    <>
      <div className="flex flex-row bg-white p-4 rounded-2xl hover:bg-gray-100">
        <div
          className="flex flex-col w-10/12 hover:cursor-pointer"
          onClick={() => setIsSelected((prev) => !prev)}
        >
          <p className="font-semibold">{courseInfo["title"]}</p>
          <p className="text-gray-700">{principalInstructor.name}</p>
          <div className="flex text-gray-600 py-1 text-sm items-center">
            <p>Más info</p>
            {isSelected ? <AiFillCaretUp /> : <AiFillCaretDown />}
          </div>
        </div>
        <button
          onClick={handleAddCourse}
          className="bg-green-500 text-white rounded-full hover:bg-green-700 flex items-center justify-center h-12 w-12"
        >
          <CgAdd size={30} />
        </button>
      </div>
      {isSelected && <CourseDetail allCourseInfo={courseInfo} />}
    </>
  );
};

export default Course;
