import { FaRedoAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const BottonButtoms = () => {
  const navigate = useNavigate();

  const handleVolver = () => {
    navigate("/");
  };
  return (
    <div>
      <div className="flex flex-col items-center pb-5">
        <div className="flex flex-col gap-3 mx-20 my-5 md:flex-row md:justify-center md:items-center text-2xl">
          <a
            href="https://www.mineducacion.gov.co/1621/article-87727.html"
            target="_blank"
            className="basis-1/4 py-3 bg-softpurple text-white rounded-2xl text-center hover:bg-softpurpledark"
          >
            Más info sobre la carga académica
          </a>

          <a
            href="https://comunidadorion.uniandes.edu.co/"
            target="_blank"
            className="basis-1/4 py-3 bg-softpurple text-white rounded-2xl text-center p-2 hover:bg-softpurpledark"
          >
            Recursos DECA para balancear mi horario
          </a>
          <button
            onClick={handleVolver}
            className="basis-1/4 px-10 md:mx-20 md:py-3 bg-hardblue text-white rounded-2xl flex gap-3 items-center justify-center hover:bg-hardbluedark"
          >
            Volver a calcular
            <FaRedoAlt />
          </button>
        </div>
      </div>
    </div>
  );
};

export default BottonButtoms;
