interface IButtonSelectTermProps {
  isSelected: boolean;
  term: 1 | 2;
  handleClick: (term: 1 | 2) => void;
}
const ButtonSelectTerm = ({
  isSelected,
  term,
  handleClick,
}: IButtonSelectTermProps) => {
  const clase: String = isSelected ? "bg-[#0037CA]" : "bg-[#D6E0FC]";
  return (
    <button
      onClick={() => handleClick(term)}
      className={"px-4 py-2 text-xl rounded-xl text-white " + clase}
    >
      Ciclo {term}
    </button>
  );
};

export default ButtonSelectTerm;
