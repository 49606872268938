import { Dispatch, SetStateAction, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Search,
  SearchedCourses,
  AddedCourses,
  Calculate,
} from "../components/_index";

//const Seneca = require("../assets/img/seneca.png");
const Flork = require("../assets/img/flork.jpeg");

interface IMainProps {
  setResultsData: Dispatch<SetStateAction<any[]>>;
  resultsData: any[];
}

const Main = ({ resultsData, setResultsData }: IMainProps) => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const totalCredits = resultsData.reduce((acc, { credits }) => {
    return acc + Number(credits);
  }, 0);

  const handleViewChange = () => {
    navigate("/results");
  };

  return (
    <>
      <div className="flex flex-col gap-4 p-10 items-center content-center font-roboto md:flex-row">
        <div className="flex flex-col justify-center items-center">
          <Search setCourses={setCourses} setLoading={setLoading} />
          <img src={Flork} width={250} alt="Flork" className="p-1" />
        </div>
        <div className="flex flex-col w-full md:w-1/3">
          <SearchedCourses
            courses={courses}
            loading={loading}
            setAddedCourses={setResultsData}
            addedCourses={resultsData}
          />
        </div>
        <div className="flex flex-col w-full md:w-1/3">
          <AddedCourses
            addedCourses={resultsData}
            setAddedCourses={setResultsData}
          />
        </div>
      </div>
      <Calculate
        handleViewChange={handleViewChange}
        totalCredits={totalCredits}
      />
    </>
  );
};

export default Main;
