import { useEffect, useState } from "react";
import {
  BsEmojiSmileFill,
  BsFillEmojiNeutralFill,
  BsFillEmojiFrownFill,
} from "react-icons/bs";

interface TermChartProps {
  term: number;
  horasClasesPresenciales: number;
  horasEstudioIndividual: number;
  totalCarga: number;
  creditosCiclo: number;
}

const TermChart = ({
  term,
  horasClasesPresenciales,
  horasEstudioIndividual,
  totalCarga,
  creditosCiclo,
}: TermChartProps) => {
  const colores = {
    si: "#05E8B6",
    maso: "#FFD85A",
    no: "#FF7961",
  };

  const states = {
    bien: {
      color: colores.si,
      icon: <BsEmojiSmileFill />,
    },
    regular: {
      color: colores.maso,
      icon: <BsFillEmojiNeutralFill />,
    },
    mal: {
      color: colores.no,
      icon: <BsFillEmojiFrownFill />,
    },
  };
  const [currentState, setCurrentState] = useState(states.bien);

  const selectIcon = () => {
    if (creditosCiclo <= 18) {
      setCurrentState(states.bien);
    } else if (creditosCiclo > 18 && creditosCiclo <= 22) {
      setCurrentState(states.regular);
    } else if (creditosCiclo > 22) {
      setCurrentState(states.mal);
    }
  };

  useEffect(() => {
    selectIcon();
  }, []);

  var widthClases =
    horasClasesPresenciales < 15
      ? "4/12"
      : horasClasesPresenciales > 15 && horasClasesPresenciales < 20
      ? "5/12"
      : "1/2";

  var widthIndividual =
    horasEstudioIndividual < 20
      ? "5/12"
      : horasEstudioIndividual > 20 && horasEstudioIndividual < 30
      ? "1/2"
      : "8/12";
  var widthLibre =
    168 - totalCarga < 100
      ? "10/12"
      : 168 - totalCarga > 100 && 168 - totalCarga < 120
      ? "11/12"
      : "11/12";

  return (
    <div className="w-11/12 md:w-5/12 h-full text-2xl flex flex-col justify-center items-start rounded-2xl bg-gray-100 mt-9 mb-3">
      <div className="bg-[#365FE5] text-white font-bold w-full text-center rounded-2xl py-2 flex justify-around items-center">
        <h3>Durante el Ciclo {term}</h3>
        <div
          style={{
            color: currentState.color,
          }}
        >
          {currentState.icon}
        </div>
      </div>
      <div className="w-full my-2 text-xl flex-1">
        <div className="bg-[#7DB2FF] w-full rounded-2xl px-6 py-2">
          <p className="text-xl">
            De las <strong>168</strong> horas que tiene una semana,
          </p>
          <p>debo dedicar:</p>
        </div>
        <div
          className={`bg-[#88A0FF] min-w-36 w-${widthClases} max-h-20 rounded-2xl px-6 py-2`}
        >
          <p className="text-lg	font-semibold flex-1 truncate">
            {Math.floor(horasClasesPresenciales)} horas y{" "}
            {Math.floor((horasClasesPresenciales % 1) * 60)} minutos{" "}
          </p>
          <p className="text-base flex-1 truncate">a clases presenciales</p>
        </div>
        <div
          className={`bg-[#B4E3FF] w-${widthIndividual} rounded-2xl px-6 py-2`}
        >
          <p className="text-lg	font-semibold overflow-y-auto truncate">
            {Math.floor(horasEstudioIndividual)} horas y{" "}
            {Math.floor((horasEstudioIndividual % 1) * 60)} minutos
          </p>
          <p className="text-base overflow-y-auto truncate">
            a actividades de clase
          </p>
        </div>
        <div className={`bg-[#A5FFEB] w-${widthLibre} rounded-2xl px-6 py-2`}>
          <p className="text-lg	font-semibold flex-1">
            {Math.floor(168 - totalCarga)} horas y{" "}
            {Math.floor(((168 - totalCarga) % 1) * 60)} minutos
          </p>
          <p className="text-base flex-1">libres</p>
        </div>
      </div>
    </div>
  );
};

export default TermChart;
