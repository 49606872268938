import { Dispatch, SetStateAction, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BalanceSemestre from "./BalanceSemestre";
import ViewHoras from "./ViewHoras";
import BottonButtoms from "../components/ResultsTerm/BottonButtoms";
import ReactGA from "react-ga";

interface IResultsProps {
  setCursosData: Dispatch<SetStateAction<any[]>>;
  data: any[];
}

const Results = (props: IResultsProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (props.data.length !== 0) {
      localStorage.setItem("data", JSON.stringify(props.data));
    }
  }, [props.data]);

  const handleViewChange = (cursosHelp: object[]) => {
    navigate("/results/courses");
    props.setCursosData(cursosHelp as never[]);
  };

  //Analytics
  const useAnalyticsEventTracker = (category = "Blog category") => {
    const eventTracker = (action = "test action", label = "test label") => {
      ReactGA.event({ category, action, label });
    };
    return eventTracker;
  };

  const gaEventTracker = useAnalyticsEventTracker("Contact us");

  //DECLARAR VARIABLES
  let creditosSemestre = 0; //Creditos del semestre
  let duracionTotalClasesMIN = 0; //Minutos de clase en total (Solo clase)
  let totalHorasSemana = 0; //Horas a la semana de carga. (Clase + individual) Promedio.
  let horasClaseSemana = 0; //horas de clase en una semana. (Solo clase)
  let estudioIndividualSemanaHoras = 0; //horas de estudio individual a la semana (Solo individual)
  //   let horasEstudioTotalDIA = 0;
  let horasEstudioIndividualDIA = 0;
  let horasClaseDia = 0;

  let maxSemanas = 0;

  //Lista de cursos
  let cursosHelp: object[] = [];

  const realData =
    props.data.length === 0
      ? JSON.parse(localStorage.getItem("data") || "[]")
      : props.data;

  console.log(realData);

  //EXTRAER DATOS
  //Recorrer cursos
  for (var _i = 0; _i < realData.length; _i++) {
    let curso = {
      nombre: "",
      codigo: "",
      creditosCurso: 0,
      periodo: "",
      term: "",
      nrc: "",
      horasTotalSemana: 0,
      duracionSesion: 0,
      horasClaseMinutos: 0,
      horasEstudioMinutos: 0,
      semanas: 0,
      hrsSemana1Credito: 0,
      sesionesCursoTotal: 0,
      totalMinutosClase: 0,
    };

    //Nombre, codigo, creditos y periodo del curso
    curso.nombre = realData[_i].title;
    curso.codigo = `${realData[_i].class}-${realData[_i].course}`;
    curso.creditosCurso = +realData[_i].credits;
    curso.periodo = realData[_i].ptrm;
    curso.term = realData[_i].term;
    curso.nrc = realData[_i].nrc;

    //Creditos totales del semestre. Suma de creditos de todos los cursos
    creditosSemestre += Number(realData[_i].credits);

    let duracionSesionDummy = 0; //llevar registro de la duracion registrada
    let contador = 0; //contador de soporte para conteo de duraciones distintas para un curso

    //RECORRIDO SCHEDULES
    for (var _j = 0; _j < realData[_i].schedules.length; _j++) {
      //DURACION DE UNA CLASE
      let timeIni = realData[_i].schedules[_j].time_ini; //Hora de inicio del curso
      let timeEnd = realData[_i].schedules[_j].time_fin; //Hora final del curso

      //Cada item NO NULO representa un dia de la semana en el que se dicta el curso
      let lista_dias = [
        realData[_i].schedules[_j].l,
        realData[_i].schedules[_j].m,
        realData[_i].schedules[_j].i,
        realData[_i].schedules[_j].j,
        realData[_i].schedules[_j].v,
        realData[_i].schedules[_j].s,
        realData[_i].schedules[_j].d,
      ];
      //Dias en los que se dicta el curso en ese horario
      let dias_no_nulos = lista_dias.filter((e): e is string => {
        return e !== null;
      });

      let startDate;
      let endDate;
      let difference;
      //Si la duracion de la clase NO es 0.
      if (timeIni !== null && timeEnd !== null) {
        //Duracion clase
        startDate = new Date(
          2020,
          5,
          5,
          Number(timeIni.slice(0, 2)),
          Number(timeIni.slice(2, 4)),
          0
        ); //hora comienzo sesion
        endDate = new Date(
          2020,
          5,
          5,
          Number(timeEnd.slice(0, 2)),
          Number(timeEnd.slice(2, 4)),
          0
        ); //hora final sesion
        difference = endDate.getTime() - startDate.getTime();
      }
      //Si la duracion de la clase es 0, haga que la diferencia sea o
      else {
        difference = 0;
      }

      difference = difference / 1000;
      let hourDifference = Math.floor(difference / 3600); //diferencia en horas
      difference -= hourDifference * 3600;
      let minuteDifference = Math.floor(difference / 60); //diferencia en minutos
      difference -= minuteDifference * 60;

      //let duracionClase = hourDifference * 60 + minuteDifference;
      //let duracionClaseHoras = duracionClase.toString().substring(0, 1);
      //let duracionClaseMins = duracionClase.toString().substring(1);
      //Duracion en minutos de la clase en ese horario a la semana.
      let totalDuracionMins =
        Number(hourDifference) * 60 + Number(minuteDifference);

      //CORREGIR DURACION DE UNA SESION
      if (totalDuracionMins !== duracionSesionDummy) {
        contador++;
        duracionSesionDummy += totalDuracionMins;
        curso.duracionSesion = duracionSesionDummy / contador; //Promedio de la duracion de las clases
      }


      //NUMERO DE SESIONES
      let inicio_schedule = new Date(
        realData[_i].schedules[_j].date_ini.replace(/-/g, "/")
      );
      let fin_schedule = new Date(
        realData[_i].schedules[_j].date_fin.replace(/-/g, "/")
      );
      var diff = Math.abs(fin_schedule.getTime() - inicio_schedule.getTime());
      var diffDays = Math.ceil(diff / (1000 * 3600 * 24));


      let semanasSchedule = Math.ceil(diffDays / 7);
      if (semanasSchedule === 18 && realData[_i].term === "202310") {
        semanasSchedule = 16;
      } //Si schedule es completo, reste semana de receso


      let sesionesSchedule = semanasSchedule * dias_no_nulos.length; //sesiones en el schedule

      //Si es un schedule de solo un dia, sume solo una sesion
      if (diff === 0) {
        sesionesSchedule = 1;
      }

      curso.sesionesCursoTotal += sesionesSchedule;
    }

    //SEMANAS QUE DURA EL CURSO
    let fecha_inicio = new Date(
      realData[_i].schedules[0].date_ini.replace(/-/g, "/")
    );
    let fecha_fin = new Date(
      realData[_i].schedules[
        realData[_i].schedules.length - 1
      ].date_fin.replace(/-/g, "/")
    );
    diff = (fecha_fin.getTime() - fecha_inicio.getTime()) / 1000;
    diff /= 60 * 60 * 24 * 7;
    let duracionSemanas = Math.abs(Math.floor(diff));
    console.log('fecha inicio: ', fecha_inicio)
    console.log('fecha final: ', fecha_fin)
    console.log('dif', diff);
    console.log('duracion semanas', duracionSemanas);

    if (
      duracionSemanas === 17 &&
      (realData[_i].ptrm === "1" || realData[_i].ptrm === "D") &&
      realData[_i].term === "202310"
    ) {
      duracionSemanas = duracionSemanas - 1;
    } //Si es periodo completo, reste semana de receso

    //Si es de medio periodo, duracion semanas igual a 8.
    else if(realData[_i].ptrm ==='8A' || realData[_i].ptrm ==='8B'){duracionSemanas=8}

    if (duracionSemanas > maxSemanas) {
      maxSemanas = duracionSemanas;
    }

    //Asignar semanas curso
    curso.semanas = duracionSemanas;
    curso.hrsSemana1Credito = 48 / duracionSemanas;

    //Horas de estudio (clase + individual) para una semana para un curso
    curso.horasTotalSemana =
      Number(realData[_i].credits) * curso.hrsSemana1Credito;

    //Minutos de clase para una semana para un curso (clase)
    curso.horasClaseMinutos =
      (curso.duracionSesion * curso.sesionesCursoTotal) / duracionSemanas;

    //Minutos totales de clase (todas las semanas)
    curso.totalMinutosClase = curso.horasClaseMinutos * duracionSemanas;

    //Duracion total de todos los cursos (todas las semanas)
    duracionTotalClasesMIN += curso.totalMinutosClase;

    //Minutos de estudio individual para el curso a la semana
    curso.horasEstudioMinutos =
      curso.horasTotalSemana * 60 - curso.horasClaseMinutos > 0
        ? curso.horasTotalSemana * 60 - curso.horasClaseMinutos
        : 0;

    //Horas totales de estudio a la semana para todos los cursos (clase + individual)
    //totalHorasSemana += Number(curso.horasTotalSemana);

    cursosHelp.push(curso);
  }

  console.log(cursosHelp);

  //cursosHelp.sort((a, b) => (a > b) ? 1 : -1);

  //guardar cursos en el local storage
  useEffect(() => {
    if (cursosHelp.length !== 0) {
      localStorage.setItem("cursos", JSON.stringify(cursosHelp));
    }
  }, [cursosHelp]);

  //Horas totales de estudio a la semana para todos los cursos (clase + individual)
  totalHorasSemana = (creditosSemestre * 48) / maxSemanas;

  horasClaseSemana = duracionTotalClasesMIN / maxSemanas / 60; // horas de clase a la semana (solo clase)
  estudioIndividualSemanaHoras = totalHorasSemana - horasClaseSemana; // horas de solo estudio a la semana (Solo individual)
  //   horasEstudioTotalDIA = totalHorasSemana / 7; //Horas de carga academica total por dia (Clase + individual)
  horasEstudioIndividualDIA = estudioIndividualSemanaHoras / 7; //Horas a Estudiar por dia (Solo individual)
  horasClaseDia = horasClaseSemana / 5; //Promedio de horas de clase al dia

  return (
    <div className="container mx-auto">
      <p className="text-xl pt-4 px-3 md:px-0">
        Si inscribo <strong>{creditosSemestre}</strong> créditos este
        semestre...
      </p>
      <div className="px-3 md:px-0">
        <div className="py-8 px-8 mx-auto m-5 bg-[#7DB2FF] justify-center items-center rounded-x space-y-2 sm:py-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6 rounded-xl">
          <div className="flex flex-col justify-center items-center">
            <p className="text-xl">
              De las <strong>{maxSemanas} semanas</strong> que tiene mi
              semestre, y de las <strong>168 horas </strong>que tiene una
              semana,
            </p>
            <p> en promedio debo dedicar:</p>
          </div>
        </div>
        <div className="flex flex-col gap-4 md:flex-row">
          <div className="basis-1/4 py-8 px-8 w-full grow bg-[#88A0FF] rounded-xl justify-center items-cente space-y-2 sm:py-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6">
            <div className="flex flex-col justify-center items-center">
              <p className="text-xl	font-semibold">
                {Math.floor(horasClaseSemana)} horas y{" "}
                {Math.floor((horasClaseSemana % 1) * 60)} minutos{" "}
              </p>
              <p> a clases presenciales a la semana</p>
            </div>
          </div>
          <div className="basis-1/2 py-8 px-8 w-full grow bg-[#B4E3FF] rounded-xl justify-center items-cente space-y-2 sm:py-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6">
            <div className="flex flex-col justify-center items-center">
              <p className="text-xl	font-semibold">
                {Math.floor(estudioIndividualSemanaHoras)} horas y{" "}
                {Math.floor((estudioIndividualSemanaHoras % 1) * 60)} minutos
              </p>
              <p>a actividades de clase a la semana</p>
              <p className="text-xs">
                {" "}
                (ej: tareas, talleres y preparar exámenes/entregas)
              </p>
            </div>
          </div>
          <div className="basis-1/4 py-8 px-8 bg-[#A5FFEB] grow w-full rounded-xl justify-center items-center space-y-2 sm:py-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6">
            <div className="flex flex-col justify-center items-center text-center">
              <p> Me quedan</p>
              <p className="text-xl	font-semibold">
                {Math.floor(168 - totalHorasSemana)} horas y{" "}
                {Math.floor(((168 - totalHorasSemana) % 1) * 60)} minutos
              </p>
              <p> disponibles a la semana</p>
            </div>
          </div>
        </div>
        <div className="flex flex-row-reverse">
          <p className="text-xs">
            {" "}
            *que debes utilizar para transporte, descansar, comer y dormir{" "}
          </p>
        </div>
      </div>

      <div className="container mx-auto m-5 ">
        <div className="flex flex-col md:flex-row justify-center items-center">
          <button
            className="bg-[#0037CA] h-fit	 hover:bg-blue-700 text-white font-bold py-3 px-10 m-2 rounded-xl"
            onClick={() => {
              handleViewChange(cursosHelp);
              gaEventTracker("call");
            }}
          >
            Haz click aquí para ver el tiempo que requiere cada clase
            <span className="inline-flex items-center justify-center w-4 h-4 ml-2 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full">
              <p>?</p>
            </span>
            {/* Este es el ícono (?) sobre el cual se hace hover para que salga el div de +info  */}
          </button>
          {/* De aquí pa bajo es el div de +info  */}
          {/* Este div debería aparecer sólo cuando se hace hover sobre el ícono de (?)  */}
          <div className="basis-1/3 py-10 px-8 bg-[#D6E0FC] text-center	 m-2 rounded-xl space-y-2 sm:py-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6">
            Aquí podrás ver las horas de clase y las horas de trabajo individual
            que requiere cada una de tus materias.
          </div>
          {/*Fin del div +info que debe aparecer y desaparecer*/}
          <a
            href="https://planet-xenon-cbe.notion.site/Realizaci-n-de-C-lculos-de-Carga-Acad-mica-81e2450b46d34d1f8587be3820a076ab"
            target="_blank"
            className="basis-1/9 py-1 bg-softpurple text-white rounded-2xl text-center hover:bg-softpurpledark"
          >
            Haz click aquí para ver como se realizan los cálculos
          </a>
        </div>
      </div>

      <div className="container mx-auto m-5">
        <div className="flex flex-col">
          <p className="text-xl p-4">
            En un <strong>día promedio</strong>, para cumplir con la carga
            académica, debo dedicar:
          </p>
          <div className="flex flex-col p-3 md:flex-row">
            <div className="container mx-auto bg-white rounded-xl shadow-md">
              <div className="flex flex-col md:flex-row h-full">
                <div className="flex bg-[#7DB2FF] rounded-xl items-center justify-center p-4">
                  <div className="flex flex-col items-center">
                    <p className="text-xl"> De las</p>
                    <p className="text-xl font-semibold">24 horas</p>
                    <p className="text-xl"> del día</p>
                  </div>
                </div>
                <div className="basis-3/4 flex flex-col items-stretch m-2">
                  <div className="py-10 px-8 w-full h-full mx-auto bg-[#88A0FF] m-2 rounded-xl justify-center items-center space-y-2 sm:py-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6">
                    <div className="flex flex-row space-x-10">
                      <p className="text-lg">
                        {" "}
                        <strong>{Math.floor(horasClaseDia)}</strong> horas y{" "}
                        <strong>{Math.floor((horasClaseDia % 1) * 60)}</strong>{" "}
                        minutos
                      </p>
                      <p> a clases presenciales</p>
                    </div>
                  </div>
                  <div className="py-10 px-8  w-full h-full mx-auto bg-[#B4E3FF] m-2 rounded-xl justify-center items-center space-y-2 sm:py-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6">
                    <div className="flex flex-row space-x-10">
                      <p className="text-lg">
                        <strong>
                          {Math.floor(horasEstudioIndividualDIA)}{" "}
                        </strong>
                        horas y{" "}
                        <strong>
                          {Math.floor((horasEstudioIndividualDIA % 1) * 60)}
                        </strong>{" "}
                        minutos{" "}
                      </p>
                      <p> a actividades de clase</p>
                    </div>
                  </div>
                  <div className="py-10 px-8 w-full h-full mx-auto bg-[#A5FFEB] m-2 rounded-xl justify-center items-center space-y-2 sm:py-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6">
                    <div className="flex flex-col items-center justify-center ">
                      <p className="text-lg"> Me quedan disponibles </p>
                      <p className="text-lg">
                        <strong>
                          {Math.floor(
                            24 - horasClaseDia - horasEstudioIndividualDIA
                          )}
                        </strong>{" "}
                        horas y{" "}
                        <strong>
                          {Math.floor(
                            ((24 - horasClaseDia - horasEstudioIndividualDIA) %
                              1) *
                              60
                          )}{" "}
                        </strong>
                        minutos entre semana
                      </p>
                      <p className="text-lg">
                        <strong>
                          {Math.floor(24 - horasEstudioIndividualDIA)}
                        </strong>{" "}
                        horas y{" "}
                        <strong>
                          {Math.floor(
                            ((24 - horasEstudioIndividualDIA) % 1) * 60
                          )}{" "}
                        </strong>
                        minutos los días de fin de semana
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container bg-white rounded-xl mx-1 shadow-md">
              <ViewHoras horasDisponibles={168 - totalHorasSemana}></ViewHoras>
            </div>
          </div>
        </div>
      </div>
      <BalanceSemestre creditos={creditosSemestre} />
      <BottonButtoms />
    </div>
  );
};

export default Results;
