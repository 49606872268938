import React, { Dispatch, SetStateAction, useEffect } from "react";
import AddedCourse from "./AddedCourse";

interface IProps {
  addedCourses: any[];
  setAddedCourses: Dispatch<SetStateAction<any[]>>;
}

const AddedCourses = ({ addedCourses = [], setAddedCourses }: IProps) => {
  useEffect(() => {
    if (localStorage.getItem("addedCourses")) {
      setAddedCourses(JSON.parse(localStorage.getItem("addedCourses") || "[]"));
    }
  }, [setAddedCourses]);

  return (
    <div className="flex flex-col gap-2">
      <h4 className="font-extrabold p-4 text-xl">Cursos agregados</h4>
      <div className="flex flex-col border rounded-xl p-4 gap-2 bg-gray-200 h-80 overflow-y-auto shadow-md">
        {addedCourses.length > 0 ? (
          addedCourses.map((course) => (
            <AddedCourse
              key={course["llave"]}
              courseInfo={course}
              addedCourses={addedCourses}
              setAddedCourses={setAddedCourses}
            />
          ))
        ) : (
          <h1 className="m-auto text-xl">No ha agregado cursos</h1>
        )}
      </div>
    </div>
  );
};

export default AddedCourses;
